import React, { useState } from "react"
import { useForm } from "react-hook-form"
import SearchIcon from "../../images/searchIcon.svg"

async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  return await response.json()
}


const SearchForm = ({ inputPlaceholder, loading, setLoading, setCatActive, setPostList, setIsSearch }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (values, e) => {
    if (loading) return;
    setCatActive('');
    setIsSearch(true)
    setLoading(true)
    postData(`${process.env.wordPressUrl}/graphql`, {
      query: `query SearchPost {
                  posts(where: {search: "${values.text}"}, first: 21) {
                    nodes {
                      id
                      title
                      uri
                      date
                      excerpt
                      featuredImage {
                        node {
                          sourceUrl
                          databaseId
                          modified
                        }
                      }
                    }
                  }
                }
            `,
    }).then(({ data }) => {
      setLoading(false)
      setPostList(data.posts);

    });
    // e.target.reset()

  }

  return (
    <form className="searchForm form" onSubmit={handleSubmit(onSubmit)}>
      <div className="searchForm__wrapper">
        <div className="form__input">
          <input
            placeholder={inputPlaceholder}
            type="text"
            {...register("text", {
              required: true,
            })}
          />
          <button type="submit"><SearchIcon /></button>
        </div>
      </div>
    </form>
  )
}
export default SearchForm
