import React from 'react';
import "./style.scss"
import { Link } from 'gatsby';
import SearchForm from './form';

const CategoryPostList = ({ catHeading, catList, catSearchInput, catActive, loading, setLoading, setCatActive, setPostList, setIsSearch }) => {
  return (
    <section className="categoryPostList">
      <div className="container container--small">
        <h2 className="categoryPostList__heading  heading--small heading--dash">
          {catHeading}
        </h2>
        <div className="categoryPostList__wrapper">
          <div className="categoryPostList__list">
            {catList.map((item, index) => (
              <Link className={`categoryPostList__list__item ${item.name === catActive ? 'categoryPostList__list__item--active' : ''}`} key={`cat_${index}`} to={item.uri}>{item.name}</Link>
            ))}
          </div>
          <div className="categoryPostList__search">
            <SearchForm inputPlaceholder={catSearchInput} loading={loading} setLoading={setLoading} setCatActive={setCatActive} setPostList={setPostList} setIsSearch={setIsSearch} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default CategoryPostList
